<template>
	<div class="content-pad">
		<FormCamera />
	</div>
</template>

<script>
import FormCamera from '@/components/FormCamera'
export default {
	components: { FormCamera },
	data() {
		return {}
	},
	mounted() {
		window.onpopstate = e => {
			console.log('onpopstate', e)
			console.log('From: ', e.state.back)
			console.log('From: ', e.state.current)
		}
	},
	methods: {},
}
</script>

<style scoped lang="scss">
.content-pad {
	background: orange;
	height: 100%;
}
</style>
