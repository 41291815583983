<template>
	<div id="camera-btn">
		<!-- {{ stream_width }} x {{ stream_height }} -->
		<video ref="ref_video" id="video" width="600" height="400" autoplay></video>
		<input type="file" accept="image/*" capture="camera" />
	</div>
</template>

<script>
// References:
// https://davidwalsh.name/browser-camera
// https://stackoverflow.com/questions/36797514/video-canvas-image-at-a-specific-resolution

export default {
	name: 'FormCamera',
	components: {},
	data() {
		return {
			stream_width: null,
			stream_height: null,
		}
	},
	mounted() {
		// Grab elements, create settings, etc.
		const refVideo = this.$refs.ref_video
		console.log(refVideo.videoHeight, refVideo.videoWidth)

		// Get access to the camera!
		if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
			// Not adding `{ audio: true }` since we only want video now
			navigator.mediaDevices.getUserMedia({ video: true }).then(function(stream) {
				//video.src = window.URL.createObjectURL(stream);
				refVideo.srcObject = stream
				refVideo.play()
			})
		}

		this.getCameraResolution()
	},
	methods: {
		async getCameraResolution() {
			// suppose we require a full HD video
			let constraints = {
				// audio: false,
				video: {
					width: { ideal: 1920 },
					height: { ideal: 1080 },
				},
			}
			let stream = await navigator.mediaDevices.getUserMedia(constraints)

			let stream_settings = stream.getVideoTracks()[0].getSettings()

			// actual width & height of the camera video
			this.stream_width = stream_settings.width
			this.stream_height = stream_settings.height

			console.log('Width: ' + this.stream_width + 'px')
			console.log('Height: ' + this.stream_height + 'px')
		},
	},
}
</script>

<style scoped lang="scss">
#camera-btn {
	background: red;
	height: 100%;
	position: relative;
}
#camera-btn input {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: lightblue;
	opacity: 0.1;
	display: none;
}
#video {
	width: 100%;
	height: 100%;
	border: solid 1px black;
	// object-fit: cover;
}
</style>
